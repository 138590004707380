import { useState, useEffect } from 'react';

export const useRequest = (url) => {
  const [response, setResponse] = useState(null);
  const [data, setData] = useState(null);
  const [method, setMethod] = useState(null);

  const sendRequest = async (method, data) => {
    setData(data);
    setMethod(method);
  };

  useEffect(() => {
    let httpRequest;
    if (method === 'POST') {
      httpRequest = async () => {
        try {
          const res = await fetch(url, {
            method: method,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const json = await res.json();
          setResponse(json);
        } catch (error) {
          setResponse({ error: 'Desconectado do servidor' });
        }
      };
    } else if (method === 'GET') {
      httpRequest = async () => {
        try {
          const res = await fetch(`${url}?${data}`, {
            method: method,
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const json = await res.json();
          setResponse(json);
        } catch (error) {
          setResponse({ error: 'Desconectado do servidor' });
        }
      };
    } else if (method === 'PUT') {
      httpRequest = async () => {
        try {
          const res = await fetch(`${url}`, {
            method: method,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const json = await res.json();
          setResponse(json);
        } catch (error) {
          setResponse({ error: 'Desconectado do servidor' });
        }
      };
    }
    if (data !== null) {
      httpRequest();
    }
  }, [data, url]);

  return { sendRequest, response };
};
