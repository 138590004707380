import React, { useState } from 'react';
import './Sidebar.css';
import * as AiIcons from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

import { NavLink, useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <div className='sidebar'>
        <div className='logo-container'>
          <img src='https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png' alt='Logo' className='logo' />
        </div>
        <div className='menu-container'>
          <div className='menu sidebar-header'>
            <div className={`container-menu-item ${isActive('/companies') ? 'side-active' : ''}`}>
              <NavLink to='/companies' className='menu-text'>
                <div className='menu-item'>
                  <li className='menu-icons'>
                    <AiIcons.AiOutlineMail />
                  </li>
                  Empresas
                </div>
              </NavLink>
            </div>
            <div className={`container-menu-item ${isActive('/groups') ? 'side-active' : ''}`}>
              <NavLink to='/groups' className='menu-text'>
                <div className='menu-item'>
                  <li className='menu-icons'>
                    <AiIcons.AiOutlineUsergroupAdd />
                  </li>
                  Grupos
                </div>
              </NavLink>
            </div>

            <div className={`container-menu-item ${isActive('/users') ? 'side-active' : ''}`}>
              <NavLink to='/users' className='menu-text'>
                <div className='menu-item'>
                  <li className='menu-icons'>
                    <AiIcons.AiOutlineUserAdd />
                  </li>
                  Usuários
                </div>
              </NavLink>
            </div>
          </div>
        </div>
        <div className={`container-menu-item-logout`}>
          <NavLink
            onClick={() => {
              localStorage.clear();
              navigate('/');
              window.location.reload();
            }}
            className='menu-text'
          >
            <div className='menu-item-logout'>
              <li className='menu-icons'>
                <AiIcons.AiOutlineLogout />
              </li>
              Logout
            </div>
          </NavLink>
        </div>
      </div>
      <div className='sidebarSmall'>
        <div className={`container-menu-item ${isActive('/companies') ? 'side-active' : ''}`}>
          <NavLink to='/companies' className='menu-text'>
            <div className='menu-item'>
              <li className='menu-icons'>
                <AiIcons.AiOutlineMail />
              </li>
              Empresas
            </div>
          </NavLink>
        </div>

        <div className={`container-menu-item ${isActive('/groups') ? 'side-active' : ''}`}>
          <NavLink to='/groups' className='menu-text'>
            <div className='menu-item'>
              <li className='menu-icons'>
                <AiIcons.AiOutlineUsergroupAdd />
              </li>
              Grupos
            </div>
          </NavLink>
        </div>

        <div className={`container-menu-item ${isActive('/users') ? 'side-active' : ''}`}>
          <NavLink to='/users' className='menu-text'>
            <div className='menu-item'>
              <li className='menu-icons'>
                <AiIcons.AiOutlineUserAdd />
              </li>
              Usuários
            </div>
          </NavLink>
        </div>
        <div className={`container-menu-item-logout`}>
          <NavLink
            onClick={() => {
              localStorage.clear();
              navigate('/');
              window.location.reload();
            }}
            className='menu-text'
          >
            <div className='menu-item-logout'>
              <li className='menu-icons'>
                <AiIcons.AiOutlineLogout />
              </li>
              Logout
            </div>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
