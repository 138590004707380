export const mySendRequest = async (method, url, data) => {
  try {
    const res = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await res.json();
    return json;
  } catch (error) {
    return { error: 'Desconectado do servidor' };
  }
};
