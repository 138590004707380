import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Row, Col, Form, Button, Card, Image } from 'react-bootstrap';
import './LoginPage.css';

import { useRequest } from '../hooks/useRequest';
import { useEnv } from '../hooks/useEnv';

const LoginPage = () => {
  const baseUrl = useEnv();

  const navigate = useNavigate();

  const { sendRequest, response } = useRequest(`${baseUrl}/login`);
  const { sendRequest: getUserInfo, response: userInfosResponse } = useRequest(`${baseUrl}/user/infos`);

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      sendRequest('POST', { username: username, password: password });
    } catch (error) {
      console.log(error);
      localStorage.removeItem('authToken');
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      getUserInfo('GET', `authToken=${authToken}`);
    }
  }, []);

  useEffect(() => {
    if (response) {
      if (response.code && response.code === 401) {
        setError(response.message);
      }
      if (response.authToken) {
        localStorage.setItem('authToken', response.authToken);
        const authToken = localStorage.getItem('authToken');
        getUserInfo('GET', `authToken=${authToken}`);
        setError(null);
      }
    }
  }, [response]);

  useEffect(() => {
    if (userInfosResponse) {
      if (userInfosResponse.userType === 'operador') {
        navigate(`/operador/${userInfosResponse.groupId}/${userInfosResponse.ramal}`);
      } else if (userInfosResponse.userType === 'condominio') {
        navigate(`/condominio/${userInfosResponse.groupId}/${userInfosResponse.ramal}`);
      } else if (userInfosResponse.userType === 'admin') {
        navigate(`/companies`);
      } else if (userInfosResponse.message === 'Unauthorized') {
        localStorage.clear();
        navigate('/');
      } else {
        setError(userInfosResponse.message);
      }
    }
  }, [userInfosResponse]);

  return (
    <Container fluid className='bg-dark min-vh-100'>
      <Row className='justify-content-center align-items-center min-vh-100'>
        <Col xs={12} sm={8} md={6} lg={4}>
          <Card bg='dark' text='light'>
            <Card.Header className='login-img-container img-center'>
              <Image src='https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png' className='login-img'></Image>
            </Card.Header>

            <Card.Body>
              <Form onSubmit={handleLogin}>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control
                    type='username'
                    placeholder='Usuário'
                    className='input-login'
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId='formBasicPassword'>
                  <Form.Control
                    type='password'
                    placeholder='Senha'
                    className='input-login'
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Group>
                {
                  <div className='container-to-center error-message'>
                    <span>{error && error}</span>
                  </div>
                }

                <Button type='submit' className='btn-submit-login w-100'>
                  ENTRAR
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
