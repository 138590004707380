import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import io from 'socket.io-client';
import './Condominium.css';

import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import { useEnv } from '../hooks/useEnv';
import { useRequest } from '../hooks/useRequest';

const Condominium = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { ramal } = useParams();
  const videoRef = useRef();
  const [isOperatorConnected, setIsOperatorConnected] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [imageUrls, setImageUrls] = useState([]);
  const baseUrl = useEnv();
  const [refreshPage, setRefreshPage] = useState(false);

  let iceCandidatesQueue = [];

  const { sendRequest: getUserInfo, response: userInfosResponse } = useRequest(`${baseUrl}/user/infos`);

  const handleIncomingStream = (stream) => {
    videoRef.current.srcObject = stream;
    videoRef.current.addEventListener('loadedmetadata', () => {
      videoRef.current.play();
      setIsOperatorConnected(true);
    });
  };

  const handlerLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  useEffect(() => {
    if (userInfosResponse) {
      if (ramal !== userInfosResponse.ramal || groupId !== userInfosResponse.groupId) {
        navigate(`/condominio/${userInfosResponse.groupId}/${userInfosResponse.ramal}`);
      }
      if (userInfosResponse.advertising) {
        setLogoUrl(userInfosResponse.advertising[0]);
        setImageUrls(userInfosResponse.advertising);
      } else {
        setImageUrls(['https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png']);
        setLogoUrl(['https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png']);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfosResponse]);

  useEffect(() => {
    if (refreshPage) {
      window.location.reload();
    }
  }, [refreshPage]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
  };

  useEffect(() => {
    let socket;
    let peerConnection;

    const connectToServer = () => {
      socket = io(baseUrl);

      socket.emit('joinRoom', { room: `${groupId}.${ramal}` });

      peerConnection = new RTCPeerConnection({
        iceServers: [
          {
            urls: 'stun:stun.l.google.com:19302',
          },
          {
            urls: 'stun:stun.relay.metered.ca:80',
          },
          {
            urls: 'turn:a.relay.metered.ca:80',
            username: '8c8cfd083b594bef0b8a56f0',
            credential: 'NRxVFAO2yBYOffMi',
          },
          {
            urls: 'turn:a.relay.metered.ca:80?transport=tcp',
            username: '8c8cfd083b594bef0b8a56f0',
            credential: 'NRxVFAO2yBYOffMi',
          },
          {
            urls: 'turn:a.relay.metered.ca:443',
            username: '8c8cfd083b594bef0b8a56f0',
            credential: 'NRxVFAO2yBYOffMi',
          },
          {
            urls: 'turn:a.relay.metered.ca:443?transport=tcp',
            username: '8c8cfd083b594bef0b8a56f0',
            credential: 'NRxVFAO2yBYOffMi',
          },
        ],
      });

      peerConnection.ontrack = (event) => {
        handleIncomingStream(event.streams[0]);
      };

      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          iceCandidatesQueue.push(event.candidate);
          socket.emit('ice-candidate', event.candidate);
        }
      };

      socket.on('offer', async (offer) => {
        if (offer) {
          try {
            await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer).then(() => {
              socket.emit('answer', answer);
              iceCandidatesQueue.forEach((candidate) => {
                peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
              });

              iceCandidatesQueue = [];
            });
          } catch (error) {
            console.error(error);
            Sentry.captureException(error);
          }
        }
      });

      socket.on('ice-candidate', async (candidate) => {
        if (candidate) {
          try {
            await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
          } catch (error) {
            console.error(error);
            Sentry.captureException(error);
          }
        }
      });

      socket.on('operator-disconnected', () => {
        setIsOperatorConnected(false);
        setRefreshPage(true);
      });

      socket.on('connect', () => {
        setRefreshPage(false);
        console.log('Connected to the server');
      });

      socket.on('connect_error', (error) => {
        console.error('connection error:', error);
        Sentry.captureException(error);

        setRefreshPage(true);
      });

      socket.on('connect_timeout', (timeout) => {
        console.error('Timeout: Falha na conexão');
        Sentry.captureException('Timeout: Falha na conexão');
        setRefreshPage(true);
      });

      socket.on('error', (error) => {
        console.error('Socket error:', error);
        Sentry.captureException(error);
        setRefreshPage(true);
      });

      peerConnection.onconnectionstatechange = () => {
        const cnnState = peerConnection.connectionState;
        console.log('RTCPeerConnection connection state changed:', cnnState);
        if (cnnState === 'disconnected') {
          setIsOperatorConnected(false);
          setRefreshPage(true);
        } else if (cnnState === 'failed') {
          setIsOperatorConnected(false);
        }
      };
    };

    if (!localStorage.getItem('authToken')) {
      navigate('/');
    } else {
      getUserInfo('GET', `authToken=${localStorage.getItem('authToken')}`);
    }

    if (ramal) {
      connectToServer();

      return () => {
        setIsOperatorConnected(false);
        socket.disconnect();
        peerConnection.close();
      };
    }
  }, [ramal]);

  return (
    <div className='condominium'>
      <div>
        <Button className='btn-cond-logout' variant='danger' onClick={handlerLogout}>
          Sair
        </Button>
      </div>
      {ramal ? (
        <>
          <div className='panel'>
            <div>
              <video className='condominium-video' ref={videoRef} hidden={!isOperatorConnected} muted autoPlay playsInline />
            </div>
            <div>
              <img className='condominium-footer-image' src={logoUrl} hidden={!isOperatorConnected} />
            </div>
          </div>
        </>
      ) : null}
      {ramal ? (
        <>
          {!isOperatorConnected && (
            <div className='condominium-logo-container'>
              {imageUrls.length > 0 && (
                <Slider className='testee' {...settings}>
                  {imageUrls.map((url, index) => (
                    <div key={index}>
                      <img src={url} alt={`Slide ${index}`} />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default Condominium;
