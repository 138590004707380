import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputMask from "react-input-mask";
import SelectFieldFetchWithId from "../selects/selectFieldFetchWithId";
import { Col, InputGroup, Row } from "react-bootstrap";
import { mySendRequest } from "../../hooks/useMySendRequest";
import { useEnv } from "../../hooks/useEnv";
import { MdContentCopy } from "react-icons/md";

function GroupsModal({
  handleSubmit,
  handleOnChange,
  formData,
  setValueByKey,
  response,
  handleResetFormData,
  showToast,
  showModal,
  handleCloseModal,
  isEditing,
  setToken,
}) {
  const baseUrl = useEnv();
  const emailInputRef = useRef(null);
  const [emailError, setEmailError] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const passwordInputRef = useRef(null);
  const [passError, setPassError] = useState("");

  const handleClose = () => {
    setEmailError("");
    setPassError("");
    handleResetFormData();
    setIsValidEmail(false);
    handleCloseModal();
  };

  useEffect(() => {
    if (oldEmail === "" && formData.email !== "") {
      setOldEmail(formData.email);
    }
  }, [formData.email]);

  useEffect(() => {
    if (response) {
      if (response.code === 200) {
        handleClose();
        handleResetFormData();
        showToast(true, response.message);
        setEmailError("");
        setPassError("");
      } else if (
        response.code === 400 &&
        response.message === "Email já cadastrado"
      ) {
        if (isEditing) {
          setEmailError(`Email já cadastrado, email anterior: ${oldEmail}`);
        } else {
          setEmailError("Email já cadastrado");
        }
        setValueByKey("email", "");
        if (emailInputRef.current) {
          emailInputRef.current.focus();
        }
      } else if (
        response.code === 400 &&
        response.message === "As senhas não coincidem"
      ) {
        setPassError("As senhas não coincidem");
        setValueByKey("password", "");
        setValueByKey("confirmPassword", "");
        if (passwordInputRef.current) {
          passwordInputRef.current.focus();
        }
      } else {
        showToast(false, response.message);
      }
    }
  }, [response]);

  const validateEmail = (e) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const emailValue = e.target.value;
    setIsValidEmail(!emailRegex.test(emailValue));
  };

  const handleEmailChange = (e) => {
    if (emailError) setEmailError("");
    validateEmail(e);
    handleOnChange(e);
  };

  const handlePasswordChange = (e) => {
    if (passError) setPassError("");
    handleOnChange(e);
  };

  const authToken = localStorage.getItem("authToken");
  const generateToken = (e) => {
    mySendRequest(
      "POST",
      `${baseUrl}/generate-token?authToken=${authToken}`,
      formData.groupId
    ).then((response) => {
      setToken(response.api_token);
    });
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <form className="form" onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {!isEditing ? "Cadastrar" : "Editar"} Grupo
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <SelectFieldFetchWithId
                  label="Empresa"
                  funcao={handleOnChange}
                  loadedVal={formData.companyId}
                  path="/company"
                  external_value={"name"}
                  fieldId={"companyId"}
                  required={true}
                />
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    className="custom-input"
                    type="text"
                    name="name"
                    placeholder="Nome"
                    required
                    value={formData.name}
                    onChange={handleOnChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    ref={emailInputRef}
                    className="custom-input"
                    type="email"
                    name="email"
                    placeholder="name@company.com"
                    required={true}
                    value={formData.email}
                    onChange={handleEmailChange}
                    isInvalid={isValidEmail || !!emailError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError ? emailError : "Email inválido"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Label>Contato</Form.Label>
                  <InputMask
                    mask="(99) 99999-9999"
                    className="custom-input"
                    name="phone"
                    placeholder="(99) 99999-9999"
                    inputMode="text"
                    required
                    value={formData.phone}
                    onChange={handleOnChange}
                  >
                    {(inputProps) => (
                      <Form.Control {...inputProps} type="text" />
                    )}
                  </InputMask>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    ref={passwordInputRef}
                    className="custom-input"
                    type="password"
                    name="password"
                    placeholder="Senha"
                    required={!isEditing}
                    value={formData.password}
                    onChange={handlePasswordChange}
                    isInvalid={!!passError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {passError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label>Confirmar Senha</Form.Label>
                  <Form.Control
                    className="custom-input"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirmar Senha"
                    required={!isEditing}
                    value={formData.confirmPassword}
                    onChange={handlePasswordChange}
                    isInvalid={!!passError}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Label>API Token</Form.Label>
                <InputGroup className="mb-3">
                  <Button variant="success" onClick={generateToken}>
                    Gerar Token
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      navigator.clipboard.writeText(formData.apiToken);
                      showToast(true, "Token copiado");
                    }}
                  >
                    <MdContentCopy />
                  </Button>
                  <Form.Control
                    disabled
                    name="apiToken"
                    value={formData.apiToken}
                    placeholder="API Token"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">
              {!isEditing ? "Cadastrar" : "Salvar"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default GroupsModal;
