import React from "react";
import { useState, useEffect } from "react";
import "../index.css";
import Sidebar from "../components/Sidebar";
import GroupsModal from "../components/modals/GroupsModal";
import { useEnv } from "../hooks/useEnv";
import Toast from "react-bootstrap/Toast";
import { ToastContainer } from "react-bootstrap";

import { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { mySendRequest } from "../hooks/useMySendRequest";
import { useNavigate } from "react-router-dom";

function GroupsPage() {
  const baseUrl = useEnv();

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  const [groupsList, setGroupsList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);

  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  const showToast = (success, message) => {
    setToastMessage(message);
    setSuccess(success);
    setShow(true);
  };

  const initialFormState = {
    companyId: "",
    email: "",
    name: "",
    phone: "",
    password: "",
    confirmPassword: "",
    apiToken: "",
  };
  const [formData, setFormData] = useState(initialFormState);

  const resetFormData = () => {
    setFormData(initialFormState);
  };

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);
    setFormData((prevFormData) => {
      return { ...prevFormData, companyId: item.companyId._id };
    });
    setShowModal(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isEditing) {
        mySendRequest(
          "POST",
          `${baseUrl}/group?authToken=${authToken}`,
          formData
        ).then(async (response) => {
          setResponse(response);
          await mySendRequest(
            "GET",
            `${baseUrl}/group?authToken=${authToken}`
          ).then((response) => {
            setGroupsList(response);
          });
        });
      } else {
        mySendRequest(
          "PUT",
          `${baseUrl}/group?authToken=${authToken}`,
          formData
        ).then(async (response) => {
          setResponse(response);
          await mySendRequest(
            "GET",
            `${baseUrl}/group?authToken=${authToken}`
          ).then((response) => {
            setGroupsList(response);
          });
        });
      }
    } catch (error) {}
  };

  const setValueByKey = (key, value) => {
    setFormData((prevFormData) => {
      return { ...prevFormData, [key]: value };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "password" && name !== "confirmPassword") {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const setToken = (token) => {
    setFormData({ ...formData, apiToken: token });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "companyId.name",
        header: "Empresa",
        size: 100,
      },
      {
        accessorKey: "name",
        header: "Nome",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "E-mail",
        size: 100,
      },
      {
        accessorKey: "phone",
        header: "Contato",
        size: 100,
      },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      await mySendRequest(
        "GET",
        `${baseUrl}/group?authToken=${authToken}`
      ).then((response) => {
        if (response.code === 401) {
          navigate("/");
        } else {
          setGroupsList(response);
        }
      });
    }
    fetchData();
  }, [baseUrl]);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="principal">
      <Sidebar />

      <div className="management-form-page">
        <div
          className="management-form-container "
          style={{ minHeight: "240px" }}
        >
          <ToastContainer
            className="p-3"
            position={"bottom-end"}
            style={{ zIndex: 9999 }}
          >
            <Toast
              show={show}
              onClose={() => {
                setShow(false);
              }}
              delay={5000}
              autohide
              className={`${success ? "bg-success" : "bg-danger"} react-toast`}
            >
              <Toast.Body className="text-white">{toastMessage}</Toast.Body>
            </Toast>
          </ToastContainer>

          <div className="header-table">
            <h1 className="h1-table">Grupos</h1>
            <button
              variant="primary"
              onClick={() => {
                setIsEditing(false);
                handleShowModal();
              }}
              className="btn-open-modal-form"
            >
              Cadastrar
            </button>
          </div>
          <div className="btn-circular-table">
            <GroupsModal
              handleSubmit={handleSubmit}
              handleOnChange={handleInputChange}
              formData={formData}
              response={response}
              setValueByKey={setValueByKey}
              handleResetFormData={resetFormData}
              // reloadRecords={reloadRecords}
              showToast={showToast}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              isEditing={isEditing}
              setToken={setToken}
            />
          </div>

          <div className="table-container">
            {groupsList && (
              <MaterialReactTable
                columns={columns}
                data={groupsList}
                enableColumnActions={false}
                initialState={{ density: "compact" }}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                // state={{ isLoading: isLoading }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    editItem(groupsList[row.id]);
                    // !isLoading && editItem(groupsList[row.id]);
                  },
                  sx: {
                    cursor: "pointer",
                    // cursor: !isLoading ? 'pointer' : 'wait',
                  },
                })}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupsPage;
