import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useEnv } from '../../hooks/useEnv';

import { mySendRequest } from '../../hooks/useMySendRequest';
import { useEffect, useState } from 'react';

function SelectFieldFetchWithId({ funcao, loadedVal, path, external_value, fieldId, label, placeholder, required }) {
  const [data, setData] = useState([]);
  const baseUrl = useEnv();
  const authToken = localStorage.getItem('authToken');

  useEffect(() => {
    mySendRequest('GET', `${baseUrl}${path}?authToken=${authToken}`).then(async (response) => {
      setData(response);
    });
  }, []);

  return (
    <>
      <InputGroup className=''>
        <Form.Label>{label}</Form.Label>
      </InputGroup>
      <Form.Select value={loadedVal} name={fieldId} onChange={(event) => funcao(event)} className='limited-select mb-3' required={required}>
        {data && placeholder !== '' ? <option value=''>{placeholder}</option> : <option value=''>Selecione o {label}</option>}

        {data ? (
          data.map((item) => (
            <option key={item['_id']} value={item['_id']}>
              {item[external_value]}
            </option>
          ))
        ) : (
          <option value=''>{loadedVal}</option>
        )}
      </Form.Select>
    </>
  );
}

export default SelectFieldFetchWithId;
