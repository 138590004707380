import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Operator from './components/Operator';
import Condominium from './components/Condominium';
import LoginPage from './pages/LoginPage';
import CompaniesPage from './pages/CompaniesPage';
import GroupsPage from './pages/GroupsPage';
import UsersPage from './pages/UsersPage';

import NotFound from './pages/NotFound';

const App = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path='/operador/:groupId/:ramal' exact element={<Operator />} />
        <Route path='/operador' exact element={<Operator />} />
        <Route path='/operador/*' exact element={<Operator />} />
        <Route path='/condominio/:groupId/:ramal' exact element={<Condominium />} />
        <Route path='/condominio' exact element={<Condominium />} />
        <Route path='/condominio/*' exact element={<Condominium />} />
        <Route path='/companies' exact element={<CompaniesPage />} />
        <Route path='/groups' exact element={<GroupsPage />} />
        <Route path='/users' exact element={<UsersPage />} />
        <Route path='/' element={<LoginPage />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
