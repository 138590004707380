import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import SelectFieldFetchWithId from '../selects/selectFieldFetchWithId';
import SelectFieldFixed from '../selects/selectFieldFixed';
import { Col, Row } from 'react-bootstrap';

function UsersModal({ handleSubmit, handleOnChange, formData, setValueByKey, response, handleResetFormData, showToast, showModal, handleCloseModal, isEditing }) {
  const emailInputRef = useRef(null);
  const ramalInputRef = useRef(null);
  const [emailError, setEmailError] = useState('');
  const [ramalError, setRamalError] = useState('');
  // const [oldEmail, setOldEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const passwordInputRef = useRef(null);
  const [passError, setPassError] = useState('');

  const userInputRef = useRef(null);
  const [userError, setUserError] = useState('');

  const handleClose = () => {
    setEmailError('');
    setRamalError('');
    setPassError('');
    setUserError('');
    handleResetFormData();
    setIsValidEmail(false);
    handleCloseModal();
  };

  useEffect(() => {
    if (response) {
      if (response.code === 200 || response.code === 201) {
        handleClose();
        handleResetFormData();
        showToast(true, response.message);
        setPassError('');
        setUserError('');
        setRamalError('');
      } else if (response.code === 400 && response.message === 'As senhas não coincidem') {
        setPassError('As senhas não coincidem');
        setValueByKey('password', '');
        setValueByKey('confirmPassword', '');
        if (passwordInputRef.current) {
          passwordInputRef.current.focus();
        }
      } else if (response.code === 400 && response.message === 'Usuário já cadastrado') {
        setUserError(response.message);
        if (userInputRef.current) {
          userInputRef.current.focus();
        }
      } else if (response.code === 400 && response.message === 'Ramal já cadastrado') {
        setRamalError(response.message);
        if (ramalInputRef.current) {
          ramalInputRef.current.focus();
        }
      } else {
        showToast(false, response.message);
      }
    }
  }, [response]);

  const validateEmail = (e) => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const emailValue = e.target.value;
    setIsValidEmail(!emailRegex.test(emailValue));
  };

  const handleEmailChange = (e) => {
    if (emailError) setEmailError('');
    validateEmail(e);
    handleOnChange(e);
  };

  const handleUserChange = (e) => {
    if (userError) setUserError('');
    handleOnChange(e);
  };

  const handlePasswordChange = (e) => {
    if (passError) setPassError('');
    handleOnChange(e);
  };

  const userTypesList = [
    { id: 1, value: 'operador', label: 'Operador' },
    { id: 2, value: 'condominio', label: 'Condomínio' },
    { id: 3, value: 'admin', label: 'Admin' },
  ];

  return (
    <>
      <Modal show={showModal} onHide={handleClose} size='xl'>
        <form className='form' onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{!isEditing ? 'Cadastrar' : 'Editar'} Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xl={4} >
                <SelectFieldFetchWithId label='Grupo' funcao={handleOnChange} loadedVal={formData.groupId} path='/group' external_value={'name'} fieldId={'groupId'} required={true} />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <Form.Group className='mb-3' controlId='formBasicName'>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control className='custom-input' type='text' name='name' placeholder='Nome' required value={formData.name} onChange={handleOnChange} />
                </Form.Group>
              </Col>
              <Col xl={3}>
                <SelectFieldFixed label='Tipo de usuário' setValue={handleOnChange} value={formData.userType} items={userTypesList} placeholder='Selecione' name={'userType'} required={true} />
              </Col>
              <Col xl={3}>
                <Form.Group className='mb-3' controlId='formBasicName'>
                  <Form.Label>Usuário</Form.Label>
                  <Form.Control ref={userInputRef} className='custom-input' type='text' name='username' placeholder='Usuário' required value={formData.username} onChange={handleUserChange} isInvalid={userError} />
                  <Form.Control.Feedback type='invalid'>{userError}</Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className='mb-3 switch-active-user-control' controlId='formBasicName'>
                  <Form.Check className='switch-active-user' type='switch' name='active' label={formData.active ? 'Ativo' : 'Inativo'} checked={formData.active} onChange={handleOnChange} />
                  {/* <label>
                    Ativar Usuário:
                    <Switch name='active' checked={formData.active} onChange={handleOnChange} />
                  </label> */}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    ref={emailInputRef}
                    className='custom-input'
                    type='email'
                    name='email'
                    placeholder='name@company.com'
                    required={true}
                    value={formData.email}
                    onChange={handleEmailChange}
                    isInvalid={isValidEmail || !!emailError}
                  />
                  <Form.Control.Feedback type='invalid'>{emailError ? emailError : 'Email inválido'}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group className='mb-3' controlId='formBasicNumber'>
                  <Form.Label>Telefone</Form.Label>
                  <InputMask mask='(99) 99999-9999' className='custom-input' name='phone' placeholder='(99) 99999-9999' inputMode='text' value={formData.phone} onChange={handleOnChange}>
                    {(inputProps) => <Form.Control {...inputProps} type='text' />}
                  </InputMask>
                </Form.Group>
              </Col>
              <Col xl={3}>
                <Form.Group className='mb-3' controlId='formBasicName'>
                  <Form.Label>Ramal</Form.Label>
                  <Form.Control ref={ramalInputRef} className='custom-input' type='text' name='ramal' placeholder='Ramal' required value={formData.ramal} onChange={handleOnChange} isInvalid={ramalError} />
                  <Form.Control.Feedback type='invalid'>{ramalError}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    ref={passwordInputRef}
                    className='custom-input'
                    type='password'
                    name='password'
                    placeholder='Senha'
                    required={!isEditing}
                    value={formData.password}
                    onChange={handlePasswordChange}
                    isInvalid={!!passError}
                  />
                  <Form.Control.Feedback type='invalid'>{passError}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xl={6}>
                <Form.Group className='mb-3' controlId='formBasicConfirmPassword'>
                  <Form.Label>Confirmar Senha</Form.Label>
                  <Form.Control
                    className='custom-input'
                    type='password'
                    name='confirmPassword'
                    placeholder='Confirmar Senha'
                    required={!isEditing}
                    value={formData.confirmPassword}
                    onChange={handlePasswordChange}
                    isInvalid={!!passError}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={handleClose}>
              Cancelar
            </Button>
            <Button type='submit' variant='success'>
              {!isEditing ? 'Cadastrar' : 'Salvar'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default UsersModal;
