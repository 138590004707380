import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import io from 'socket.io-client';
import './Operator.css';
import Button from 'react-bootstrap/Button';
import { useRequest } from '../hooks/useRequest';
import { useEnv } from '../hooks/useEnv';
import ActiveCond from './ActiveCond';

const Operator = () => {
  const navigate = useNavigate();
  const baseUrl = useEnv();
  const { groupId } = useParams();
  const { ramal } = useParams();
  const [user, setUser] = useState(null);
  const [activeCond, setActiveCond] = useState('');
  const [condsList, setCondsList] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const videoRef = useRef();
  const socketRef = useRef();
  const [clientToCall, setClientToCall] = useState(null);
  const { sendRequest, response } = useRequest(`${baseUrl}/condominios`);
  const { sendRequest: getUserInfo, response: userInfosResponse } = useRequest(`${baseUrl}/user/infos`);
  const retryTimeoutRef = useRef(null);

  const iceCandidatesBuffer = useRef([]);

  const handlerLogout = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken === null) {
      navigate('/');
    }
    sendRequest('GET', `authToken=${authToken}`);
  }, []);

  useEffect(() => {
    if (response) {
      if (!response.error) {
        setCondsList(response);
      } else {
        setErrorMessage(response.error);
      }
    }
  }, [response]);

  useEffect(() => {
    if (clientToCall) {
      navigate(`/operador/${groupId}/${clientToCall}`);
      const cond = response.find((cond) => cond.ramal === clientToCall);
      if (cond) {
        setActiveCond(cond.name);
      }
      setClientToCall(null);
    }
  }, [clientToCall]);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    getUserInfo('GET', `authToken=${authToken}`);
  }, []);

  useEffect(() => {
    if (userInfosResponse) {
      if (groupId !== userInfosResponse.groupId) {
        navigate(`/operador/${userInfosResponse.groupId}/${userInfosResponse.ramal}`);
      }
      setUser(userInfosResponse);
      localStorage.setItem('ramal', userInfosResponse.ramal);
    }
  }, [userInfosResponse]);

  const handleActiveCond = (record) => {
    if (record.name === activeCond) {
      setActiveCond(null);
      navigate(`/operador/${groupId}/${user.ramal}`);
    } else {
      setActiveCond(record.name);
      navigate(`/operador/${groupId}/${record.ramal}`);
    }
  };

  const Table = ({ data }) => (
    <table className='operator-table table-operador'>
      <thead className='op-table-head operador-th'>
        <tr className='operador-tr'>
          <th>Nome do condomínio</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((record) => (
            <tr key={record._id} onClick={() => handleActiveCond(record)} className={record.name === activeCond ? 'active-row operador-tr' : 'operador-tr'}>
              <td className='operador-td'>{record.name}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const initializeConnection = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
    socketRef.current = io(baseUrl);
    socketRef.current.emit('joinRoom', { room: `${groupId}.${ramal}` });
    socketRef.current.emit('join-as-operator');
    const peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
        {
          urls: 'stun:stun.relay.metered.ca:80',
        },
        {
          urls: 'turn:a.relay.metered.ca:80',
          username: '8c8cfd083b594bef0b8a56f0',
          credential: 'NRxVFAO2yBYOffMi',
        },
        {
          urls: 'turn:a.relay.metered.ca:80?transport=tcp',
          username: '8c8cfd083b594bef0b8a56f0',
          credential: 'NRxVFAO2yBYOffMi',
        },
        {
          urls: 'turn:a.relay.metered.ca:443',
          username: '8c8cfd083b594bef0b8a56f0',
          credential: 'NRxVFAO2yBYOffMi',
        },
        {
          urls: 'turn:a.relay.metered.ca:443?transport=tcp',
          username: '8c8cfd083b594bef0b8a56f0',
          credential: 'NRxVFAO2yBYOffMi',
        },
      ],
    });

    const initializeMediaStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        stream.getTracks().forEach((track) => {
          try {
            peerConnection.addTrack(track, stream);
          } catch (error) {
            console.error(error);
            Sentry.captureException(error);
          }
        });
      }
    };
    initializeMediaStream();
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit('ice-candidate', event.candidate);
      }
    };
    peerConnection.onnegotiationneeded = async () => {
      await peerConnection.createOffer().then((offer) => {
        peerConnection.setLocalDescription(offer).then(() => {
          socketRef.current.emit('offer', offer);
        });
      });
    };
    peerConnection.onconnectionstatechange = () => {
      const cnnState = peerConnection.connectionState;
      if (cnnState === 'connecting') {
        retryTimeoutRef.current = setTimeout(initializeConnection, 15000);
      } else if (cnnState === 'connected') {
        clearTimeout(retryTimeoutRef.current);
      }
    };
    socketRef.current.on('answer', async (answer) => {
      if (answer && peerConnection.signalingState === 'have-local-offer') {
        try {
          await peerConnection.setRemoteDescription(new RTCSessionDescription(answer)).then(() => {
            while (iceCandidatesBuffer.current.length) {
              const bufferedCandidate = iceCandidatesBuffer.current.shift();
              peerConnection.addIceCandidate(new RTCIceCandidate(bufferedCandidate));
            }
          });
        } catch (error) {
          console.error('Error setting remote description: ', error);
          Sentry.captureException(error);
        }
      }
    });
    socketRef.current.on('ice-candidate', async (candidate) => {
      if (candidate && peerConnection.remoteDescription && peerConnection.remoteDescription.type) {
        try {
          await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
        } catch (error) {
          console.error('Error adding ICE candidate: ', error);
          Sentry.captureException(error);
        }
      } else if (candidate) {
        iceCandidatesBuffer.current.push(candidate);
      }
    });
    socketRef.current.on('call-to', async (client) => {
      if (client) {
        setClientToCall(client);
      }
    });
    socketRef.current.on('call-back', async () => {
      navigate(`/operador/${groupId}/${localStorage.getItem('ramal')}`);
      setActiveCond(null);
    });
    socketRef.current.on('connect_error', (error) => {
      initializeConnection();
      Sentry.captureException(error);
    });
    socketRef.current.on('connect_timeout', (error) => {
      initializeConnection();
      Sentry.captureException(error);
    });
    socketRef.current.on('error', (error) => {
      initializeConnection();

      console.error('Socket error:', error);
      Sentry.captureException(error);
    });
  }, [ramal]);

  useEffect(() => {
    initializeConnection();
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [initializeConnection]);

  return (
    <div className='operador'>
      <div className='operador-painel'>
        <video className='operador-video' ref={videoRef} autoPlay playsInline />
        <img className='operador-logo-strike' src='https://strike-view-advertising.s3.amazonaws.com/63fd5010bc8c90fc606d8a53/Strike-TI-Logo.png' />
        <Button className='btn-operador-logout' variant='danger' onClick={handlerLogout}>
          Sair
        </Button>
      </div>
      {condsList && (
        <>
          <ActiveCond activeCond={activeCond} />
          <div className='operador-cond-list'>{condsList && <Table data={condsList} />}</div>
        </>
      )}
      {!condsList && (
        <>
          <div className='fail-load-condlist'>
            <div className='fail-text'>{errorMessage}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default Operator;
