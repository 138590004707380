import React from 'react';
import { useState, useEffect } from 'react';
import '../index.css';
import Sidebar from '../components/Sidebar';
import CompaniesModal from '../components/modals/CompaniesModal';
import { useEnv } from '../hooks/useEnv';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';

import { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { mySendRequest } from '../hooks/useMySendRequest';
import { useNavigate } from 'react-router-dom';

function CompaniesPage() {
  const baseUrl = useEnv();

  const navigate = useNavigate();

  const authToken = localStorage.getItem('authToken');

  const [companiesList, setCompaniesList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);

  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);

  const showToast = (success, message) => {
    setToastMessage(message);
    setSuccess(success);
    setShow(true);
  };

  const initialFormState = {
    email: '',
    name: '',
    phone: '',
    password: '',
    confirmPassword: '',
  };
  const [formData, setFormData] = useState(initialFormState);

  const resetFormData = () => {
    setFormData(initialFormState);
  };

  function editItem(item) {
    setIsEditing(true);
    setFormData(item);
    setFormData((prevFormData) => {
      return { ...prevFormData, password: '' };
    });
    setShowModal(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!isEditing) {
        mySendRequest('POST', `${baseUrl}/company?authToken=${authToken}`, formData).then(async (response) => {
          setResponse(response);
          await mySendRequest('GET', `${baseUrl}/company?authToken=${authToken}`).then((response) => {
            setCompaniesList(response);
          });
        });
      } else {
        mySendRequest('PUT', `${baseUrl}/company?authToken=${authToken}`, formData).then(async (response) => {
          setResponse(response);
          await mySendRequest('GET', `${baseUrl}/company?authToken=${authToken}`).then((response) => {
            setCompaniesList(response);
          });
        });
      }
    } catch (error) {}
  };

  const setValueByKey = (key, value) => {
    setFormData((prevFormData) => {
      return { ...prevFormData, [key]: value };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== 'password' && name !== 'confirmPassword') {
      setFormData({ ...formData, [name]: value.toLowerCase() });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name', //normal accessorKey
        header: 'Nome',
        size: 200,
      },
      {
        accessorKey: 'email',
        header: 'E-mail',
        size: 150,
      },

      {
        accessorKey: 'phone',
        header: 'Contato',
        size: 150,
      },
    ],
    []
  );

  useEffect(() => {
    async function fetchData() {
      await mySendRequest('GET', `${baseUrl}/company?authToken=${authToken}`).then((response) => {
        if (response.code === 401) {
          navigate('/');
        } else {
          setCompaniesList(response);
        }
      });
    }
    fetchData();
  }, [baseUrl]);

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='principal'>
      <Sidebar />

      <div className='management-form-page'>
        <div className='management-form-container ' style={{ minHeight: '240px' }}>
          <ToastContainer className='p-3' position={'bottom-end'} style={{ zIndex: 9999 }}>
            <Toast
              show={show}
              onClose={() => {
                setShow(false);
              }}
              delay={5000}
              autohide
              className={`${success ? 'bg-success' : 'bg-danger'} react-toast`}
            >
              <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
            </Toast>
          </ToastContainer>

          <div className='header-table'>
            <h1 className='h1-table'>Empresas</h1>
            <button
              variant='primary'
              onClick={() => {
                setIsEditing(false);
                handleShowModal();
              }}
              className='btn-open-modal-form'
            >
              Cadastrar
            </button>
          </div>
          <div className='btn-circular-table'>
            <CompaniesModal
              handleSubmit={handleSubmit}
              handleOnChange={handleInputChange}
              formData={formData}
              response={response}
              setValueByKey={setValueByKey}
              handleResetFormData={resetFormData}
              // reloadRecords={reloadRecords}
              showToast={showToast}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              isEditing={isEditing}
            />
          </div>

          <div className='table-container'>
            {companiesList && (
              <MaterialReactTable
                columns={columns}
                data={companiesList}
                enableColumnActions={false}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                // state={{ isLoading: isLoading }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: () => {
                    editItem(companiesList[row.id]);
                    // !isLoading && editItem(companiesList[row.id]);
                  },
                  sx: {
                    cursor: 'pointer',
                    // cursor: !isLoading ? 'pointer' : 'wait',
                  },
                })}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesPage;
