import React from 'react'

function ActiveCond({activeCond}) {
    return (
        <div className='operador-active-cond'>
            {activeCond && activeCond ? <p className='red'>{`Transmitindo para: ${activeCond}`}</p> : <p> Você não está transmitindo</p>}
        </div>
    )
}

export default ActiveCond