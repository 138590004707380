export const useEnv = () => {
  let adress;
  if (process.env.NODE_ENV === 'development') {
    adress = 'http://localhost:8000';
  } else if (process.env.NODE_ENV === 'production') {
    adress = 'https://strikeview.com.br:8443';
  } else {
    adress = 'http://localhost:8000';
  }
  return adress;
};
